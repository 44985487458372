import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const EnvironmentSetup = () => {
  return (
    <LayoutComponent>
      <h3>Configure Jenkins or GitHub Actions with GAINSS for Automation</h3>
      <div className="testimonials">
        <h5>Note</h5>
        <p>
          You must be a site admin or project admin to configure the automation
          execution tool for your project in GAINSS.
        </p>
      </div>
      <div>
        <p>
          Follow the steps below to set up and configure the tools for running
          automation tests directly from GAINSS:
        </p>
        <ul>
          <li>
            Navigate to <strong>Project Settings &gt; Integration</strong>
          </li>
          <li>
            Select <strong>Execution Tool</strong>. Choose either Jenkins or
            GitHub Actions as your execution tool.
          </li>
          <li>After selecting the tool, provide configuration details.</li>
          <li>
            If you selected Jenkins, enter:
            <ul>
              <li>
                <strong>Jenkins URL</strong>
              </li>
              <li>
                <strong>Username</strong>
              </li>
              <li>
                <strong>Auth Token</strong>
              </li>
            </ul>
            <br />
            If you selected GitHub Actions, enter:
            <ul>
              <li>
                <strong>Github Repo URL</strong>
              </li>
              <li>
                <strong>Username</strong>
              </li>
              <li>
                <strong>Auth Token(PAT)</strong>
              </li>
            </ul>
          </li>
          <li>
            Click on <strong>Save</strong> to verify the details and finish
            configuration.
          </li>
        </ul>
      </div>
    </LayoutComponent>
  )
}

export default EnvironmentSetup
